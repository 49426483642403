<template>
  <section>
    <div class="custom-search d-flex align-items-center">
      <div
        class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
      >
        <template v-if="projectInfo?.status === false">
          <template v-if="$permissionAbility(PROJECT_APPROVE, permissions)">
            <b-button
              class="flex-shrink-0 mr-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-success"
              v-on:click="approveProject">
              Approve
            </b-button>
          </template>

          <template v-if="$permissionAbility(PROJECT_EDIT, permissions)">
            <b-button
              class="flex-shrink-0 mr-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              v-on:click="showProjectDetailsModal"
            >
              Edit Project Details
            </b-button>
          </template>
        </template>

        <template
          v-if="
            $permissionAbility(PROJECT_APPROVED_EDIT, permissions) &&
            projectInfo?.status
          "
        >
          <b-button
            class="flex-shrink-0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="gradient-primary"
            v-on:click="showApprovedProjectDetailsModal"
          >
            Edit Approved Project Details
          </b-button>
        </template>
      </div>
    </div>
    <b-row>
      <b-col lg="12" md="12">
        <template v-if="approveErrorMsg">
          <b-alert variant="danger" show>
            <h4 class="alert-heading">{{ approveErrorMsg }}!</h4>
          </b-alert></template
        >


        <b-row>
          <b-col lg="4" md="4">
            <b-card>
              <h4>Project Details</h4></br>
              <b-card-text>
                <b-list-group flush>
                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Title: <b v-if="projectInfo?.lead?.data?.title">
                    {{ projectInfo?.lead?.data?.title }}
                  </b>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Department: <b v-if="projectInfo?.department">
                    {{ projectInfo?.department?.data?.name }}
                  </b>
                  </h6>
                    </b-list-group-item
                    >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Duration: <b v-if="projectInfo?.time_duration">
                    {{ projectInfo?.time_duration }} Months
                  </b>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  >
                  <h6 class="text-base font-weight-semibold">
                    Proposals:
                  </h6>
                  <b v-if="leadLastActivityLog?.file?.length > 0">
                    <ul>
                      <li v-for="(item, index) in leadLastActivityLog.file" :key="index">
                        <b-link :href="item?.original_url" target="_blank">
                          {{ item?.file_name }}
                        </b-link>
                      </li>
                    </ul>
                  </b>

                 </b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Milestone: <b v-if="projectInfo?.milestone">
                    {{ projectInfo?.milestone }}
                  </b>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Extra Milestone: <b v-if="projectInfo?.extra_milestone">
                    {{ projectInfo?.extra_milestone }}
                  </b>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Total Milestone: <b v-if="projectInfo?.total_milestone">
                    {{ projectInfo?.total_milestone }}
                  </b>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Status: <template v-if="projectInfo?.status">
                    <b-badge pill variant="light-success">Approved</b-badge>
                  </template>
                    <template v-else-if="projectInfo?.status === false">
                      <b-badge pill variant="light-danger">Pending</b-badge>
                    </template>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Project Type: <b-badge pill variant="light-primary">
                    {{ formatType(projectInfo?.project_type) }}</b-badge
                  >
                  </h6></b-list-group-item
                  >

                </b-list-group>
              </b-card-text>
            </b-card>
          </b-col>

          <b-col lg="4" md="4">
            <b-card>
              <h4>Payment Details</h4></br>
              <b-card-text>
                <b-list-group flush>
                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Amount: <template v-if="projectInfo?.amount">
                      <FormatCurrency :amount="projectInfo?.amount" :currency="projectInfo?.currency" />
                  </template>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Total Amount: <template v-if="projectInfo?.total_amount">
                      <FormatCurrency :amount="projectInfo?.total_amount" :currency="projectInfo?.currency" />
                  </template>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Extra Amount:   <FormatCurrency :amount="projectInfo?.extra_amount" :currency="projectInfo?.extra_currency" />

                  </h6>
                  </b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    VAT:  <FormatCurrency :amount="projectInfo?.vat_amount" :currency="projectInfo?.currency" />

                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Tax:  <FormatCurrency :amount="projectInfo?.tax_amount" :currency="projectInfo?.currency" />

                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Extra VAT & TAX:
                    <template v-if="(projectInfo?.extra_vat_amount > 0) && (projectInfo?.extra_tax_amount > 0)">
                      <FormatCurrency :amount="projectInfo?.extra_vat_amount" :currency="projectInfo?.currency" /> & <FormatCurrency :amount="projectInfo?.extra_tax_amount" :currency="projectInfo?.currency" />
                    </template>
                    <template v-else><b>0</b></template>


                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Total VAT:  <FormatCurrency :amount="projectInfo?.total_vat_amount" :currency="projectInfo?.currency" />

                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Total TAX:  <FormatCurrency :amount="projectInfo?.total_tax_amount" :currency="projectInfo?.currency" />

                  </h6></b-list-group-item
                  >


                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Grand Amount:  <FormatCurrency :amount="projectInfo?.grand_amount" :currency="projectInfo?.currency" />

                  </h6></b-list-group-item
                  >



                </b-list-group>
              </b-card-text>
            </b-card>
          </b-col>

          <b-col lg="4" md="4">
            <b-card>
              <h4>Contact Details</h4></br>
              <b-card-text>
                <b-list-group flush>
                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Key Contact Person: <template v-if="projectInfo?.lead?.data?.key_contact_person">
                        {{
                          projectInfo?.lead?.data?.key_contact_person
                        }}</template
                  >
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Company Name: <template v-if="projectInfo?.lead?.data?.company_name">
                    {{ projectInfo?.lead?.data?.company_name }}</template
                  >
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Email Address: <template v-if="projectInfo?.lead?.data?.email">
                      <b-link
                          :href="'mailto:' + projectInfo?.lead?.data?.email"
                      >
                        {{ projectInfo?.lead?.data?.email }}
                      </b-link></template
                  >
                    <template v-else>
                      <b-badge pill variant="light-danger">N/A</b-badge>
                    </template>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Website: <template v-if="projectInfo?.lead?.data?.website_link">
                    <b-link
                        :href="'https://' + projectInfo?.lead?.data?.website_link"
                        target="_blank"
                    >
                      {{ projectInfo?.lead?.data?.website_link }}
                    </b-link></template
                  >
                    <template v-else>
                      <b-badge pill variant="light-danger">N/A</b-badge>
                    </template>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Contact Number: <template v-if="projectInfo?.lead?.data?.mobile">
                    <b-link :href="'tel:' + projectInfo?.lead?.data?.mobile">
                    {{ projectInfo?.lead?.data?.mobile }}
                  </b-link></template
                  >
                    <template v-else>
                      <b-badge pill variant="light-danger">N/A</b-badge>
                    </template>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Address: {{ projectInfo?.lead?.data?.address }}
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Lead Assigned To: <template v-if="projectInfo?.lead?.data?.team">
                    <div>

                      <span v-for="(item, index) in projectInfo?.lead?.data?.team || []" :key="index">
                        <userCard v-for="(user, userIndex) in item" :key="userIndex" :user="user" />
                      </span>

                    </div></template
                  >

                    <template v-else>
                      <b-badge pill variant="light-danger">N/A</b-badge>
                    </template>
                  </h6></b-list-group-item
                  >

                  <b-list-group-item
                  ><h6 class="text-base font-weight-semibold">
                    Project Estimated By: <template v-if="projectInfo?.createdBy">
                      <userCard :user="projectInfo?.createdBy?.data"/>
                    </template
                  >
                    <template v-else>
                      <b-badge pill variant="light-danger">N/A</b-badge>
                    </template>
                  </h6></b-list-group-item
                  >


                </b-list-group>
              </b-card-text>
            </b-card>
          </b-col>

        </b-row>

        <b-row>
          <b-col lg="8" cols="8">
            <b-card>
              <div>
                <div
                  class="custom-search d-flex align-items-center justify-content-end"
                >
                  <div
                    class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
                  >
                    <template
                      v-if="$permissionAbility(MILESTONE_CREATE, permissions)"
                    >
                      <template>
                        <b-button
                          class="flex-shrink-0"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          v-on:click="showProjectDetailMilestoneModal"
                        >
                          {{
                            !projectInfo?.status
                              ? "Create Milestone"
                              : "Create Extra Milestone"
                          }}
                        </b-button>
                      </template>
                    </template>
                  </div>
                </div>
                <vue-good-table
                  mode="remote"
                  styleClass="vgt-table table-custom-style striped"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  :totalRows="totalRecords"
                  :isLoading.sync="isLoading"
                  :rows="rows"
                  :sort-options="{
                    enabled: true,
                    multipleColumns: true,
                    initialSortBy: [
                      { field: 'last_contact_date', type: 'desc' },
                    ],
                  }"
                  :columns="columns"
                >
<!--                  :pagination-options="{-->
<!--                  enabled: true,-->
<!--                  perPage: pageLength,-->
<!--                  }"-->
                  <template slot="table-row" slot-scope="props">
                    <template
                      v-if="props?.column?.field === 'format_milestone_info'"
                    >
                      <div v-if="props?.row?.title" class="mt-1">
                        <b-link
                          v-if="props?.row?.status == 'paid'"
                          v-on:click="onTaskBoardPage(props?.row?.id)"
                        >
                          <b>{{ props?.row?.title }}</b>
                          <b-badge pill variant="light-primary">{{
                            formatMilestoneType(props?.row?.is_extra)
                          }}</b-badge>
                        </b-link>

                        <span v-else
                          >{{ props?.row?.title }}
                          <b-badge pill variant="light-primary">{{
                            formatMilestoneType(props?.row?.is_extra)
                          }}</b-badge></span
                        >
                      </div>

                      <div v-if="props?.row?.payment_type" class="mt-1 mb-1">
                        Payment Type:
                        <b-badge pill variant="light-primary">{{
                          formatType(props?.row?.payment_type)
                        }}</b-badge>
                      </div>
                    </template>
                    <template v-if="props?.column?.field === 'format_amount'">
                      <div v-if="props?.row?.amount" class="table-cell-margin">
                        <span>Amount: <FormatCurrency :currency="props?.row?.currency" :amount="props?.row?.amount" /></span>
                      </div>
                      <div
                        v-if="props?.row?.vat_amount"
                        class="table-cell-margin"
                      >
                        <span>VAT: <FormatCurrency :currency="props?.row?.currency" :amount="props?.row?.vat_amount" /> </span>
                      </div>
                      <div
                        v-if="props?.row?.tax_amount"
                        class="table-cell-margin"
                      >
                        <span>TAX: <FormatCurrency :currency="props?.row?.currency" :amount="props?.row?.tax_amount" /> </span>
                      </div>
                      <div
                        v-if="props?.row?.grand_amount"
                        class="table-cell-margin"
                      >
                        <span
                          >Total Amount: <FormatCurrency :currency="props?.row?.currency" :amount="props?.row?.grand_amount" />
                        </span>
                      </div>
                    </template>

                    <template v-if="props?.column?.field === 'format_currency'">
                      <FormatCurrency :type="'currency'" :currency="props?.row?.currency" :amount="props?.row?.total_amount" />
                    </template>

                    <template v-if="props?.column?.field === 'format_status'">
                      <template v-if="props?.row?.status == 'paid'">
                        <b-badge pill variant="light-success">Paid</b-badge>
                      </template>
                      <template v-else-if="props?.row?.status == 'due'">
                        <b-badge pill variant="light-danger">Due</b-badge>
                      </template>
                      <template v-else-if="props?.row?.status == 'forward'">
                        <b-badge pill variant="light-info">Forwarded</b-badge>
                      </template>
                    </template>

                    <template
                      v-if="
                        $permissionAbility(MILESTONE_EDIT, permissions) ||
                        $permissionAbility(permissions) ||
                        $permissionAbility(MILESTONE_DELETE, permissions)
                      "
                    >
                    </template>
                    <span v-if="props?.column?.field === 'action'">
                      <template
                        v-if="
                          $permissionAbility(MILESTONE_EDIT, permissions) ||
                          $permissionAbility(
                            PROJECT_APPROVED_EDIT,
                            permissions
                          ) ||
                          $permissionAbility(MILESTONE_DELETE, permissions)
                        "
                      >
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                            />
                          </template>

                          <template
                            v-if="
                              $permissionAbility(MILESTONE_EDIT, permissions) ||
                              $permissionAbility(
                                PROJECT_APPROVED_EDIT,
                                permissions
                              )
                            "
                          >
                            <b-dropdown-item v-on:click="onShow(props)">
                              <feather-icon icon="Edit2Icon" class="mr-50" />
                              <span>Edit</span>
                            </b-dropdown-item>
                          </template>

                          <template
                            v-if="
                              $permissionAbility(
                                MILESTONE_DELETE,
                                permissions
                              ) ||
                              $permissionAbility(
                                PROJECT_APPROVED_EDIT,
                                permissions
                              )
                            "
                          >
                            <b-dropdown-item
                              v-on:click="onDelete(props?.row?.id)"
                            >
                              <feather-icon icon="TrashIcon" class="mr-50" />
                              <span>Delete</span>
                            </b-dropdown-item>
                          </template>
                        </b-dropdown>
                      </template>
                      <template v-else>
                        <b-badge pill variant="light-danger"
                          >Not Permitted</b-badge
                        >
                      </template>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props?.column?.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Showing 1 to </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['10', '25', '50', '100', '500']"
                          class="mx-1"
                          @input="
                            (value) =>
                              props.perPageChanged({ currentPerPage: value })
                          "
                        />
                        <span class="text-nowrap">
                          of {{ props.total }} entries
                        </span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props?.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="
                            (value) => props.pageChanged({ currentPage: value })
                          "
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </div>

              <!-- Project Details Modal-->
              <b-modal
                id="modal-project-details-form"
                centered
                title="Edit Project Details"
                hide-footer
                @hidden="hideProjectDetailsModal"
                no-close-on-backdrop
                size="xl"
              >
                <validation-observer ref="projectDetailsValidation">
                  <b-form v-on:submit.prevent="projectDetailsValidationForm">
                    <b-row>
                      <!-- Change  project (lead) title -->
                      <b-col md="4" lg="4" xs="12">
                        <b-form-group
                          label="Project Title (Lead Title) *"
                          label-for="title"
                        >
                          <ValidationProvider
                            name="project title (lead title)"
                            v-slot="{ errors }"
                            vid="title"
                            rules="required"
                          >
                            <b-form-input
                              id="title"
                              type="text"
                              v-model="title"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter Project Title (Lead Title) "
                              class="custom-font"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <!-- select project type -->
                      <b-col md="4" lg="4" xs="12">
                        <b-form-group
                          label="Project Type *"
                          label-for="projectType"
                        >
                          <ValidationProvider
                            name="project type"
                            v-slot="{ errors }"
                            vid="project_type"
                            rules="required"
                          >
                            <v-select
                              id="projectType"
                              placeholder="Choose Project Type"
                              v-model="projectType"
                              :options="projectTypeConstants"
                              :reduce="(country) => country.value"
                              label="name"
                              class="custom-font"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>

                      <b-col md="4" lg="4" xs="12">
                        <b-form-group
                          label="Payment Type *"
                          label-for="paymentType"
                        >
                          <ValidationProvider
                            name="payment type"
                            v-slot="{ errors }"
                            vid="payment_type"
                            rules="required"
                          >
                            <v-select
                              id="paymentType"
                              placeholder="Choose Payment Type"
                              v-model="paymentType"
                              :options="paymentTypeConstants"
                              :reduce="(country) => country.value"
                              label="name"
                              class="custom-font"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>

                      <!-- select currency -->
                      <b-col md="12" lg="3">
                        <b-form-group label="Currency *" label-for="currency">
                          <ValidationProvider
                            name="Currency"
                            v-slot="{ errors }"
                            vid="currency"
                            rules="required"
                          >
                            <v-select
                              id="currency"
                              placeholder="Choose a Currency"
                              v-model="selectCurrency"
                              :options="currencyOption"
                              :reduce="(option) => option?.currency"
                              label="currency"
                              class="custom-font"
                              :disabled="isApprovedProjectEdit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>

                      <b-col md="12" lg="3">
                        <b-form-group label="Final Amount *" label-for="amount">
                          <validation-provider
                            #default="{ errors }"
                            name="amount"
                            vid="amount"
                            rules="required|min_value:1"
                          >
                            <b-form-input
                              id="amount"
                              type="text"
                              v-model="amount"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter Final Amount"
                              class="custom-font"
                              v-on:input="calculateProjectInfo"
                              :disabled="isApprovedProjectEdit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" lg="2">
                        <b-form-group label="VAT *" label-for="vatAmount">
                          <validation-provider
                            #default="{ errors }"
                            name="vat amount"
                            vid="vat_amount"
                            rules="required|min_value:0"
                          >
                            <b-form-input
                              id="vatAmount"
                              type="text"
                              v-model="vatAmount"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter VAT Amount"
                              class="custom-font"
                              v-on:input="calculateProjectInfo"
                              :disabled="isApprovedProjectEdit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" lg="2">
                        <b-form-group label="TAX *" label-for="taxAmount">
                          <validation-provider
                            #default="{ errors }"
                            name="tax amount"
                            vid="tax_amount"
                            rules="required|min_value:0"
                          >
                            <b-form-input
                              id="taxAmount"
                              type="text"
                              v-model="taxAmount"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter TAX Amount"
                              class="custom-font"
                              v-on:input="calculateProjectInfo"
                              :disabled="isApprovedProjectEdit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" lg="2">
                        <b-form-group
                          label="Grand Total *"
                          label-for="grandAmount"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="grand total"
                            vid="grand_amount"
                            rules="required|min_value:0"
                          >
                            <b-form-input
                              id="grandAmount"
                              type="text"
                              v-model="grandAmount"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Grand Total"
                              class="custom-font"
                              disabled
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="6" lg="6" xs="12">
                        <b-form-group
                          label="Time Duration (Months) *"
                          label-for="timeDuration"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="time duration"
                            vid="time_duration"
                            rules="required|numeric|max_value:12|min_value:1"
                          >
                            <b-form-input
                              id="timeDuration"
                              type="number"
                              v-model="timeDuration"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter Time Duration"
                              class="custom-font"
                              :disabled="isApprovedProjectEdit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="6" lg="6" xs="12">
                        <b-form-group label="Milestone *" label-for="milestone">
                          <validation-provider
                            #default="{ errors }"
                            name="milestone"
                            vid="milestone"
                            rules="required|numeric|max_value:12|min_value:1"
                          >
                            <b-form-input
                              type="number"
                              v-model="milestones"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter Number of Milestones"
                              class="custom-font"
                              :disabled="isApprovedProjectEdit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- loading button -->
                    <template v-if="isProjectDetailsFormLoading">
                      <b-button class="float-right" variant="primary" disabled>
                        <b-spinner small />
                        Loading...
                      </b-button>
                    </template>

                    <!-- submit button -->
                    <template v-else>
                      <b-button
                        type="submit"
                        class="float-right"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                      >
                        Submit
                      </b-button>
                    </template>
                  </b-form>
                </validation-observer>
              </b-modal>

              <!-- Project Milestone Modal-->
              <b-modal
                id="modal-project-milestone-form"
                centered
                :title="
                  modalType == 'edit' ? 'Edit Milestone' : 'Add Milestone'
                "
                hide-footer
                @hidden="hideProjectMileStoneModal"
                no-close-on-backdrop
              >
                <validation-observer ref="projectMilestoneValidation">
                  <b-form v-on:submit.prevent="projectMilestoneValidationForm">
                    <b-row>
                      <!-- milestone amount-->
                      <b-col md="12" lg="12" xs="12">
                        <b-form-group label="Milestone Title" label-for="post-fix">
                          <validation-provider
                            #default="{ errors }"
                            name="post_fix"
                            vid="post_fix"
                          >
                            <b-form-input
                              id="post_fix"
                              type="text"
                              v-model="postFix"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter Milestone Title"
                              class="custom-font"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" lg="12" xs="12">
                        <b-form-group
                          label="Amount "
                          label-for="amount"
                          class="required-label"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="amount"
                            vid="amount"
                            rules="required|min_value:1"
                          >
                            <b-form-input
                              id="amount"
                              type="text"
                              v-model="amountMilestone"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter Final Amount"
                              class="custom-font"
                              v-on:input="calculateMilestoneInfo"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" lg="12" xs="12">
                        <b-form-group
                          label="VAT "
                          class="required-label"
                          label-for="vatAmount"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="vat amount"
                            vid="vat_amount"
                            rules="required|min_value:0"
                          >
                            <b-form-input
                              type="text"
                              v-model="vatMilestone"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter VAT Amount"
                              class="custom-font"
                              v-on:input="calculateMilestoneInfo"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" lg="12" xs="12">
                        <b-form-group
                          label="TAX "
                          label-for="taxAmount"
                          class="required-label"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="tax amount"
                            vid="tax_amount"
                            rules="required|min_value:0"
                          >
                            <b-form-input
                              type="text"
                              v-model="taxMilestone"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter TAX Amount"
                              class="custom-font"
                              v-on:input="calculateMilestoneInfo"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" lg="12" xs="12">
                        <b-form-group
                          label="Total "
                          class="required-label"
                          label-for="grandTotal"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="total amount"
                            vid="grand_amount"
                            rules="required|min_value:0"
                          >
                            <b-form-input
                              type="text"
                              v-model="totalMilestone"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Total Amount"
                              class="custom-font"
                              disabled
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- milestone payment type -->
                      <b-col md="12" lg="12" xs="12">
                        <b-form-group
                          label="Payment Type "
                          label-for="paymentType"
                          class="required-label"
                        >
                          <ValidationProvider
                            name="payment type"
                            v-slot="{ errors }"
                            vid="payment_type"
                            rules="required"
                          >
                            <v-select
                              id="paymentType"
                              placeholder="Choose Payment Type"
                              v-model="paymentTypeMilestone"
                              :options="paymentTypeConstants"
                              label="name"
                              :reduce="(country) => country.value"
                              class="custom-font"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>

                      <!-- Deadline  -->
                      <b-col md="12" lg="12" xs="12">
                        <b-form-group
                          label="Milestone Deadline"
                          label-for="date"
                          class="required-label"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="deadline"
                            vid="date"
                            rules="required"
                          >
                            <b-form-datepicker
                              v-model="deadlineMilestone"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Enter Milestone Deadline"
                              class="custom-font"
                              locale="en-US"
                              place
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>

                      <!-- <template v-if="isPaymentDeadlineRequired">
                        <b-col md="12" lg="12" xs="12">
                          <b-form-group
                            label="Payment Deadline "
                            label-for="date"
                            class="required-label"
                          >
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="payment deadline"
                              vid="payment_deadline"
                              rules="required"
                            >
                              <b-form-datepicker
                                v-model="paymentDeadlineMilestone"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Enter Payment Deadline"
                                class="custom-font"
                                locale="en-US"
                                place
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </ValidationProvider>
                          </b-form-group>
                        </b-col>
                      </template> -->
                      <template>
                        <b-col md="12" lg="12" xs="12">
                          <b-form-group
                            label="Payment Deadline "
                            label-for="date"
                          >
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="payment deadline"
                              vid="payment_deadline"
                            >
                              <b-form-datepicker
                                v-model="paymentDeadlineMilestone"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Enter Payment Deadline"
                                class="custom-font"
                                locale="en-US"
                                place
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </ValidationProvider>
                          </b-form-group>
                        </b-col>
                      </template>
                    </b-row>

                    <!-- loading button -->
                    <template v-if="isProjectMilestoneFormLoading">
                      <b-button class="float-right" variant="primary" disabled>
                        <b-spinner small />
                        Loading...
                      </b-button>
                    </template>

                    <!-- submit button -->
                    <template v-else>
                      <b-button
                        type="submit"
                        class="float-right"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                      >
                        Submit
                      </b-button>
                    </template>
                  </b-form>
                </validation-observer>
              </b-modal>
            </b-card>
          </b-col>

          <!-- Project Activity Log -->
          <b-col lg="4" md="4">
            <b-card>
              <div style="max-height: 500px; overflow-y: auto">
                <b-card title="Project Activity Log">
                  <app-timeline>
                    <template v-for="item in projectActivityLogs">
                      <!-- Project Approved Log -->
                      <template
                          v-if="
                    item?.log_name == 'projects' &&
                    item?.description == 'updated' &&
                    item?.properties?.attributes?.status !=
                      item?.properties?.old?.status
                  "
                      >
                        <app-timeline-item
                            :key="item?.id"
                            icon="InfoIcon"
                            variant="primary"
                            :title="`Project Approved by ${item?.properties?.attributes?.updatedBy?.name}`"
                            :subtitle="formatDateTime(item?.created_at)"
                            :time="formatYear(item?.created_at)"
                        />
                      </template>
                      <!-- Project Created Log -->
                      <template
                          v-else-if="
                    item?.log_name == 'projects' &&
                    item?.description == 'created'
                  "
                      >
                        <app-timeline-item
                            :key="item?.id"
                            icon="InfoIcon"
                            variant="primary"
                            :title="`Project Information Created by ${item?.properties?.attributes?.createdBy?.name}`"
                            :subtitle="formatDateTime(item?.created_at)"
                            :time="formatYear(item?.created_at)"
                        />
                      </template>
                      <!-- Project Updated Log -->
                      <template
                          v-else-if="
                    item?.log_name == 'projects' &&
                    item?.description == 'updated'
                  "
                      >
                        <app-timeline-item
                            :key="item?.id"
                            icon="InfoIcon"
                            variant="primary"
                        >
                          <div
                              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                          >
                            <h6>
                              {{
                                `Project Information updated by ${item?.properties?.attributes?.updatedBy?.name}`
                              }}
                            </h6>
                            <small class="text-muted">{{
                                formatYear(item?.created_at)
                              }}</small>
                            <span class="align-bottom">{{
                                formatDateTime(item?.created_at)
                              }}</span>
                            <br />
                            <b-button
                                v-b-toggle="`project-change-list-${item?.id}`"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                size="sm"
                                variant="outline-primary"
                                class="mt-1"
                            >
                              Show Changes
                            </b-button>
                            <b-collapse :id="`project-change-list-${item?.id}`">
                              <b-row class="custom-font">
                                <b-col cols="6" class="pr-1">
                                  <b-list-group flush class="mt-1">
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                      <span><b>Previous</b></span>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Title:</b>
                                  {{
                                    formatType(item?.properties?.old?.title)
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Type:</b>
                                  {{
                                    formatType(
                                        item?.properties?.old?.project_type
                                    )
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Payment:</b>
                                  {{
                                    formatType(
                                        item?.properties?.old?.payment_type
                                    )
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Duration:</b>
                                  {{
                                    `${item?.properties?.old?.time_duration} Months`
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Amount: </b>
                                  {{ item?.properties?.old?.amount }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Extra: </b>
                                  {{
                                    item?.properties?.old?.extra_amount
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Total: </b>
                                  {{
                                    item?.properties?.old?.total_amount
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>VAT: </b>
                                  {{ item?.properties?.old?.vat_amount }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Extra: </b>
                                  {{
                                    item?.properties?.old?.extra_vat_amount
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>VAT Total: </b>
                                  {{
                                    item?.properties?.old?.total_vat_amount
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>TAX: </b>
                                  {{ item?.properties?.old?.tax_amount }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Extra: </b>
                                  {{
                                    item?.properties?.old?.extra_tax_amount
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>TAX Total: </b>
                                  {{
                                    item?.properties?.old?.total_tax_amount
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Grand Total: </b>
                                  {{
                                    item?.properties?.old?.grand_amount
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Milestones:</b>
                                  {{ item?.properties?.old?.milestone }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Extra: </b>
                                  {{
                                    item?.properties?.old?.extra_milestone
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Total: </b>
                                  {{
                                    item?.properties?.old?.total_milestone
                                  }}</span
                                >
                                    </b-list-group-item>
                                  </b-list-group>
                                </b-col>
                                <b-col cols="6">
                                  <b-list-group flush class="mt-1">
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                      <span><b>Updated</b></span>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>Title: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes?.title !==
                                    item?.properties?.old?.title
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      formatType(
                                          item?.properties?.attributes?.title
                                      )
                                    }}</span
                                  > </template
                                      ><template v-else>
                                  <span>
                                    {{
                                      formatType(
                                          item?.properties?.attributes?.title
                                      )
                                    }}
                                  </span>
                                    </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b> Type: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.project_type !==
                                    item?.properties?.old?.project_type
                                  "
                                      >
                                  <span class="text-danger">{{
                                      formatType(
                                          item?.properties?.attributes?.project_type
                                      )
                                    }}</span> </template
                                      ><template v-else>
                                  <span>
                                    {{
                                      formatType(
                                          item?.properties?.attributes
                                              ?.project_type
                                      )
                                    }}
                                  </span>
                                    </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>Payment: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.payment_type !==
                                    item?.properties?.old?.payment_type
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      formatType(
                                          item?.properties?.attributes
                                              ?.payment_type
                                      )
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>{{
                                      formatType(
                                          item?.properties?.attributes?.payment_type
                                      )
                                    }}</span>
                                    </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b> Duration: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.time_duration !==
                                    item?.properties?.old?.time_duration
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      `${item?.properties?.attributes?.time_duration} Months`
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>
                                    {{
                                      `${item?.properties?.attributes?.time_duration} Months`
                                    }}</span
                                  >
                                    </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    ><b>Amount: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes?.amount !==
                                    item?.properties?.old?.amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.amount
                                    }}</span
                                  > </template
                                      ><template v-else>
                                  <span>{{
                                      item?.properties?.attributes?.amount
                                    }}</span>
                                      </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    ><b>Extra: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.extra_amount !==
                                    item?.properties?.old?.extra_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.extra_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>{{
                                      item?.properties?.attributes?.extra_amount
                                    }}</span>
                                      </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    ><b>Total: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.total_amount !==
                                    item?.properties?.old?.total_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.total_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>{{
                                      item?.properties?.attributes?.total_amount
                                    }}</span>
                                      </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    ><b>VAT: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes?.vat_amount !==
                                    item?.properties?.old?.vat_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.vat_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>{{
                                      item?.properties?.attributes?.vat_amount
                                    }}</span>
                                      </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    ><b>Extra: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.extra_vat_amount !==
                                    item?.properties?.old?.extra_vat_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes
                                          ?.extra_vat_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>{{
                                      item?.properties?.attributes
                                          ?.extra_vat_amount
                                    }}</span>
                                      </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    ><b>VAT Total: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.total_vat_amount !==
                                    item?.properties?.old?.total_vat_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes
                                          ?.total_vat_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>{{
                                      item?.properties?.attributes
                                          ?.total_vat_amount
                                    }}</span>
                                      </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    ><b>TAX:</b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes?.tax_amount !==
                                    item?.properties?.old?.tax_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.tax_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>{{
                                      item?.properties?.attributes?.tax_amount
                                    }}</span>
                                      </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    ><b>Extra: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.extra_tax_amount !==
                                    item?.properties?.old?.extra_tax_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes
                                          ?.extra_tax_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>{{
                                      item?.properties?.attributes
                                          ?.extra_tax_amount
                                    }}</span>
                                      </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    ><b>TAX Total: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.total_tax_amount !==
                                    item?.properties?.old?.total_tax_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes
                                          ?.total_tax_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>{{
                                      item?.properties?.attributes
                                          ?.total_tax_amount
                                    }}</span>
                                      </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    ><b>Grand Total: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.grand_amount !==
                                    item?.properties?.old?.grand_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.grand_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>{{
                                      item?.properties?.attributes?.grand_amount
                                    }}</span>
                                      </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>Milestones: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes?.milestone !==
                                    item?.properties?.old?.milestone
                                  "
                                      >
                                  <span class="text-danger">
                                    <span>
                                      {{
                                        item?.properties?.attributes?.milestone
                                      }}</span
                                    ></span
                                  ></template
                                      ><template v-else>
                                  <span>
                                    {{
                                      item?.properties?.attributes?.milestone
                                    }}</span
                                  >
                                    </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>Extra: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.extra_milestone !==
                                    item?.properties?.old?.extra_milestone
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes
                                          ?.extra_milestone
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>
                                    {{
                                      item?.properties?.attributes
                                          ?.extra_milestone
                                    }}</span
                                  >
                                    </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>Total: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.total_milestone !==
                                    item?.properties?.old?.total_milestone
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes
                                          ?.total_milestone
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>
                                    {{
                                      item?.properties?.attributes
                                          ?.total_milestone
                                    }}</span
                                  >
                                    </template>
                                    </b-list-group-item>
                                  </b-list-group>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </div>
                        </app-timeline-item>
                      </template>

                      <!-- Milestone Created Log -->
                      <template
                          v-else-if="
                    item?.log_name == 'milestones' &&
                    item?.description == 'created'
                  "
                      >
                        <app-timeline-item
                            :key="item?.id"
                            icon="InfoIcon"
                            variant="info"
                        >
                          <div
                              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                          >
                            <h6>
                              Milestone :
                              <b-badge variant="info" class="badge-glow">
                                {{ item?.properties?.attributes?.title }}
                              </b-badge>
                              {{
                                `Created by ${item?.properties?.attributes?.createdBy?.name}`
                              }}
                            </h6>
                            <small class="text-muted">{{
                                formatYear(item?.created_at)
                              }}</small>
                            <span class="align-bottom">{{
                                formatDateTime(item?.created_at)
                              }}</span>
                          </div>
                        </app-timeline-item>
                      </template>
                      <!-- Milestone Updated Log -->
                      <template
                          v-else-if="
                    item?.log_name == 'milestones' &&
                    item?.description == 'updated'
                  "
                      >
                        <app-timeline-item
                            :key="item?.id"
                            icon="InfoIcon"
                            variant="info"
                        >
                          <div
                              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                          >
                            <h6>
                              Milestone :
                              <b-badge variant="info" class="badge-glow">
                                {{ item?.properties?.attributes?.title }}
                              </b-badge>
                              {{
                                `updated by ${item?.properties?.attributes?.updatedBy?.name}`
                              }}
                            </h6>
                            <small class="text-muted">{{
                                formatYear(item?.created_at)
                              }}</small>
                            <span class="align-bottom">{{
                                formatDateTime(item?.created_at)
                              }}</span>
                            <br />

                            <b-button
                                v-b-toggle="`milestone-change-list-${item?.id}`"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                size="sm"
                                variant="outline-primary"
                                class="mt-1"
                            >
                              Show Changes
                            </b-button>
                            <b-collapse :id="`milestone-change-list-${item?.id}`">
                              <b-row class="custom-font">
                                <b-col cols="6" class="pr-1">
                                  <b-list-group flush class="mt-1">
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                      <span><b>Previous</b></span>
                                    </b-list-group-item>

                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Title:</b>
                                  {{ item?.properties?.old?.title }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Amount:</b>
                                  {{ item?.properties?.old?.amount }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>VAT Amount:</b>
                                  {{ item?.properties?.old?.vat_amount }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>TAX Amount:</b>
                                  {{ item?.properties?.old?.tax_amount }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Total Amount:</b>
                                  {{
                                    item?.properties?.old?.grand_amount
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Deadline:</b>
                                  {{
                                    formatDateTime(item?.properties?.old?.date)
                                  }}</span
                                >
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                <span
                                ><b>Payment Type:</b>
                                  {{
                                    formatType(
                                        item?.properties?.old?.payment_type
                                    )
                                  }}</span
                                >
                                    </b-list-group-item>
                                  </b-list-group>
                                </b-col>
                                <b-col cols="6">
                                  <b-list-group flush class="mt-1">
                                    <b-list-group-item
                                        class="d-flex justify-content-between align-items-center bg-transparent"
                                    >
                                      <span><b>Updated</b></span>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>Amount: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes?.title !==
                                    item?.properties?.old?.title
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.title
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>
                                    {{ item?.properties?.attributes?.title }}
                                  </span>
                                    </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>Amount: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes?.amount !==
                                    item?.properties?.old?.amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>
                                    {{ item?.properties?.attributes?.amount }}
                                  </span>
                                    </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>VAT Amount: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes?.vat_amount !==
                                    item?.properties?.old?.vat_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.vat_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>
                                    {{
                                      item?.properties?.attributes?.vat_amount
                                    }}
                                  </span>
                                    </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>TAX Amount: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes?.tax_amount !==
                                    item?.properties?.old?.tax_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.tax_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>
                                    {{
                                      item?.properties?.attributes?.tax_amount
                                    }}
                                  </span>
                                    </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>Total Amount: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.grand_amount !==
                                    item?.properties?.old?.grand_amount
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      item?.properties?.attributes?.grand_amount
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>
                                    {{
                                      item?.properties?.attributes?.grand_amount
                                    }}
                                  </span>
                                    </template>
                                    </b-list-group-item>
                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>Deadline: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes?.date !==
                                    item?.properties?.old?.date
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      formatDateTime(
                                          item?.properties?.attributes?.date
                                      )
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>
                                    {{
                                      formatDateTime(
                                          item?.properties?.attributes?.date
                                      )
                                    }}
                                  </span>
                                    </template>
                                    </b-list-group-item>

                                    <b-list-group-item
                                        class="justify-content-between align-items-center bg-transparent"
                                    >
                                      <b>Payment Type: </b>
                                      <template
                                          v-if="
                                    item?.properties?.attributes
                                      ?.payment_type !==
                                    item?.properties?.old?.payment_type
                                  "
                                      >
                                  <span class="text-danger">
                                    {{
                                      formatType(
                                          item?.properties?.attributes
                                              ?.payment_type
                                      )
                                    }}</span
                                  ></template
                                      ><template v-else>
                                  <span>
                                    {{
                                      formatType(
                                          item?.properties?.attributes
                                              ?.payment_type
                                      )
                                    }}
                                  </span>
                                    </template>
                                    </b-list-group-item>
                                  </b-list-group>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </div>
                        </app-timeline-item>
                      </template>
                    </template>
                  </app-timeline>
                </b-card>
              </div>
            </b-card>
          </b-col>
        </b-row>


      </b-col>

    </b-row>
  </section>
</template>
<!--  -->
<script>
import {
  BLink,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BButton,
  BForm,
  BModal,
  BCol,
  BRow,
  BCardText,
  BFormTextarea,
  BSpinner,
  BAlert,
  VBTooltip,
  BFormDatepicker,
  BCollapse,
  BListGroup,
  BListGroupItem,
  VBToggle,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, max, size, ext, max_value, min_value } from "@validations";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { projectTypeConstants } from "@/helpers/constant/projectTypeConstant";
import { paymentTypeConstants } from "@/helpers/constant/paymentTypeConstant";
import {
  PROJECT_APPROVE,
  PROJECT_EDIT,
  PROJECT_APPROVED_EDIT,
  MILESTONE_CREATE,
  MILESTONE_EDIT,
  MILESTONE_DELETE,
} from "@/helpers/permissionsConstant";
import FormatCurrency from "@/layouts/components/FormatCurrency.vue";
import UserCard from "@/layouts/components/UserCard.vue";
export default {
  name: "ProjectDetailsView",
  components: {
    UserCard,
    FormatCurrency,
    BLink,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BCardText,
    BSpinner,
    BFormTextarea,
    flatPickr,
    BAlert,
    BFormDatepicker,
    AppTimeline,
    AppTimelineItem,
    BCollapse,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    heightFade,
  },
  data() {
    return {
      PROJECT_APPROVED_EDIT,
      PROJECT_APPROVE,
      PROJECT_EDIT,
      MILESTONE_CREATE,
      MILESTONE_EDIT,
      MILESTONE_DELETE,
      projectActivityLogs: "",
      projectInfo: "",
      //project info
      title: "",
      leadLastActivityLog: "",
      leadId: "",
      projectTypeConstants,
      paymentTypeConstants,
      projectType: "",
      paymentType: "",
      amount: "",
      vatAmount: "",
      taxAmount: "",
      timeDuration: "",
      grandAmount: "",
      milestones: "",
      milestoneError: "",
      selectCurrency: "",
      currencyOption: [],
      //milestone info

      projectId: "",
      milestoneId: "",
      paymentTypeMilestoneValue: "",
      paymentTypeMilestone: "",
      amountMilestone: "",
      postFix: "",
      vatMilestone: "",
      taxMilestone: "",
      totalMilestone: "",
      deadlineMilestone: "",
      paymentDeadlineMilestone: "",
      modalType: "",
      allMilestoneBoard: "",

      pageLength: 100,
      columns: [
        {
          label: "Milestone Deadline",
          field: "date",
          formatFn: this.formatDateTime,
          sortable: false,
        },
        {
          label: "Milestone Info",
          field: "format_milestone_info",
          sortable: false,
        },
        {
          label: "Payment Deadline",
          field: "payment_deadline",
          formatFn: this.formatDateTime,
          sortable: false,
        },
        {
          label: "Amount Info",
          field: "format_amount",
          sortable: false,
        },
        {
          label: "Currency",
          field: "format_currency",
          sortable: false,
        },

        {
          label: "Status",
          field: "format_status",
          formatFn: this.formatType,
          sortable: false,
        },

        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      approveErrorMsg: "",
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isProjectDetailsFormLoading: false,
      isProjectMilestoneFormLoading: false,
      isPaymentDeadlineRequired: false,
      isApprovedProjectEdit: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const [getCurrencies, getMilestoneBoard] = await Promise.all([
        this.getCurrencies(),
        this.getMilestoneBoardInfo(),
        this.loadItems(),
      ]);

      this.allMilestoneBoard = getMilestoneBoard?.data?.data;

      this.currencyOption = (getCurrencies?.data?.data || []).map((item) => {
        return {
          currency: item?.currency,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    async getCurrencies() {
      return await this.$api.get("api/currencies/all");
    },
    async getMilestoneBoardInfo() {
      return await this.$api.get("api/milestone-task-boards");
    },
    formatYear(value) {
      if (value) {
        return this.$moment(value).format("YYYY");
      }
    },

    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },
    formatType(value) {
      if (value == null) {
        return "N/A";
      }
      const res = value.charAt(0).toUpperCase() + value.slice(1);
      return res;
    },
    calculateMilestoneInfo() {
      const amount = parseFloat(this.amountMilestone).toFixed(2);
      const vat = parseFloat(this.vatMilestone).toFixed(2);
      const tax = parseFloat(this.taxMilestone).toFixed(2);
      const grandTotal = parseFloat(amount) + parseFloat(vat) + parseFloat(tax);
      if (!isNaN(grandTotal)) {
        this.totalMilestone = parseFloat(grandTotal).toFixed(2);
      }
    },
    calculateProjectInfo() {
      const amount = parseFloat(this.amount).toFixed(2);
      const vat = parseFloat(this.vatAmount).toFixed(2);
      const tax = parseFloat(this.taxAmount).toFixed(2);
      const grandTotal = parseFloat(amount) + parseFloat(vat) + parseFloat(tax);
      this.grandAmount = parseFloat(grandTotal).toFixed(2);
    },
    formatMilestoneType(value) {
      if (value) {
        return "Extra";
      }
      return "Primary";
    },
    async approveProject() {
      if (!this.rows[0].payment_deadline) {
        this.$swal({
          title: "Error!",
          icon: "error",
          text: "Payment Deadline For the First Milestone Must be Set!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            // Perform any action you want here
          }
        });
      } else {
        this.$swal({
          title: "Warning!",
          text: "Are You Sure To Approve This Project?",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Ok",
          showLoaderOnConfirm: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$api.put(
                `api/projects/${this.$route.params.id}/approve`
              );
              this.loadItems();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Project Details Successfully Updated",
                },
              });
              this.approveErrorMsg = "";
            } catch (error) {
              if (error?.response?.data?.message) {
                this.approveErrorMsg = error?.response?.data?.message;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }
            }
          }
        });
      }
    },
    async singleProjectInfo() {
      return await this.$api.get(
        `api/projects/${this.$route.params.id}?include=milestones,lead.user,lead.activityLogs,lead.team,department,createdBy`
      );
    },
    async getProjectActivityLog() {
      return await this.$api.get(
        `api/projects/${this.$route.params.id}/activitylog`
      );
    },
    showProjectDetailMilestoneModal() {
      this.modalType = "create";
      this.$bvModal.show("modal-project-milestone-form");
    },
    async onShow(props) {
      if (props?.index == 0) {
        this.isPaymentDeadlineRequired = true;
      } else {
        this.isPaymentDeadlineRequired = false;
      }
      this.modalType = "edit";
      const value = props?.row;

      this.milestoneId = value?.id;
      this.amountMilestone = value?.amount;
      this.vatMilestone = value?.vat_amount;
      this.taxMilestone = value?.tax_amount;
      this.totalMilestone = value?.grand_amount;
      this.paymentTypeMilestone = value?.payment_type;
      this.deadlineMilestone = value?.date;
      this.paymentDeadlineMilestone = value?.payment_deadline;
      this.postFix = value?.post_fix;
      this.$bvModal.show("modal-project-milestone-form");
    },
    hideProjectMileStoneModal() {
      this.$bvModal.hide("modal-project-milestone-form");
      this.resetProjectMilestoneModal();
    },

    resetProjectMilestoneModal() {
      this.milestoneId = "";
      this.amountMilestone = "";
      this.paymentTypeMilestone = "";
      this.vatMilestone = "";
      this.taxMilestone = "";
      this.totalMilestone = "";
      this.deadlineMilestone = "";
      this.paymentDeadlineMilestone = "";
      this.postFix = "";

      this.isPaymentDeadlineRequired = false;
    },
    showApprovedProjectDetailsModal() {
      this.leadId = this.projectInfo?.lead?.data?.id;
      this.title = this.projectInfo?.lead?.data?.title;
      this.projectType = this.projectInfo?.project_type;
      this.amount = this.projectInfo?.amount;
      this.timeDuration = this.projectInfo?.time_duration;
      this.milestones = this.projectInfo?.milestone;
      this.paymentType = this.projectInfo?.payment_type;
      this.vatAmount = this.projectInfo?.vat_amount;
      this.taxAmount = this.projectInfo?.tax_amount;
      this.grandAmount = this.projectInfo?.grand_amount;
      this.selectCurrency = this.projectInfo?.currency;
      this.isApprovedProjectEdit = true;
      this.$bvModal.show("modal-project-details-form");
    },
    showProjectDetailsModal() {
      this.leadId = this.projectInfo?.lead?.data?.id;
      this.title = this.projectInfo?.lead?.data?.title;
      this.projectType = this.projectInfo?.project_type;
      this.amount = this.projectInfo?.amount;
      this.timeDuration = this.projectInfo?.time_duration;
      this.milestones = this.projectInfo?.milestone;
      this.paymentType = this.projectInfo?.payment_type;
      this.vatAmount = this.projectInfo?.vat_amount;
      this.taxAmount = this.projectInfo?.tax_amount;
      this.grandAmount = this.projectInfo?.grand_amount;
      this.selectCurrency = this.projectInfo?.currency;

      this.$bvModal.show("modal-project-details-form");
    },

    hideProjectDetailsModal() {
      this.$bvModal.hide("modal-project-details-form");
      this.resetProjectDetailsModal();
    },
    resetProjectDetailsModal() {
      this.projectType = "";
      this.amount = "";
      this.timeDuration = "";
      this.milestones = "";
      this.paymentType = "";
      this.selectCurrency = "";
      this.title = "";
      this.isApprovedProjectEdit = false;
      // this.currencyOption = [];
    },

    onTaskBoardPage(id) {
      const boardItem = this.allMilestoneBoard.find(
        (item) => item?.milestone_id === id
      );

      if (boardItem) {
        this.$router.push({
          name: "admin-task-board-details",
          params: { id: boardItem.id },
        });
      }
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        // this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      // this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      // this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      // this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      // this.loadItems();
    },

    async loadItems() {
      try {
        const [resProject, projectActivityLog] = await Promise.all([
          this.singleProjectInfo(),
          this.getProjectActivityLog(),
        ]);

        const projectData = resProject?.data?.data;

        let leadActivityLog = projectData?.lead?.data?.activityLogs?.data;

        leadActivityLog = leadActivityLog.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA;
        });

        this.leadLastActivityLog = leadActivityLog[0];

        this.projectActivityLogs = projectActivityLog?.data?.data;

        this.projectInfo = projectData;
        this.rows = this.projectInfo?.milestones?.data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(
              `/api/projects/${this.$route.params?.id}/milestone/${id}/force-delete`
            );

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Milestone Successfully Deleted ",
              },
            });

            this.loadItems();
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    projectDetailsValidationForm: async function () {
      this.$refs.projectDetailsValidation.validate().then(async (success) => {
        if (success) {
          this.$swal({
            title: "Warning!",
            text: "Any change in Project amount must be manually matched with Milestones!",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.$refs.projectDetailsValidation.reset();
                this.isProjectDetailsFormLoading = true;

                await this.$api.put(`api/projects/${this.$route.params.id}`, {
                  lead_id: this.leadId,
                  title: this.title,
                  project_type: this.projectType,
                  amount: this.amount,
                  time_duration: this.timeDuration,
                  milestone: this.milestones,
                  payment_type: this.paymentType,
                  vat_amount: this.vatAmount,
                  tax_amount: this.taxAmount,
                  grand_amount: this.grandAmount,
                  currency: this.selectCurrency,
                });

                this.isProjectDetailsFormLoading = false;
                this.hideProjectDetailsModal();
                this.loadItems();

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Project Details Successfully Updated",
                  },
                });
              } catch (error) {
                this.isProjectDetailsFormLoading = false;
                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.financialEstimationValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },
    projectMilestoneValidationForm: async function () {
      this.$refs.projectMilestoneValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.projectMilestoneValidation.reset();
            if (this.modalType == "edit") {
              this.isProjectMilestoneFormLoading = true;

              await this.$api.put(
                `api/projects/${this.$route.params.id}/milestone/${this.milestoneId}/update`,
                {
                  post_fix: this.postFix,
                  amount: this.amountMilestone,
                  vat_amount: this.vatMilestone,
                  tax_amount: this.taxMilestone,
                  grand_amount: this.totalMilestone,
                  date: this.deadlineMilestone,
                  payment_type: this.paymentTypeMilestone,
                  payment_deadline: this.paymentDeadlineMilestone,
                }
              );

              this.isProjectMilestoneFormLoading = false;
              this.hideProjectMileStoneModal();

              this.loadItems();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Milestone Successfully Updated",
                },
              });
            } else {
              this.isProjectMilestoneFormLoading = true;

              await this.$api.post(
                `/api/projects/${this.$route.params.id}/milestone/store`,
                {
                  post_fix: this.postFix,
                  amount: this.amountMilestone,
                  vat_amount: this.vatMilestone,
                  tax_amount: this.taxMilestone,
                  grand_amount: this.totalMilestone,
                  date: this.deadlineMilestone,
                  payment_type: this.paymentTypeMilestone,
                  payment_deadline: this.paymentDeadlineMilestone,
                }
              );

              this.isProjectMilestoneFormLoading = false;
              this.hideProjectMileStoneModal();
              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "New Milestone Successfully Added",
                },
              });
            }
          } catch (error) {
            this.isProjectMilestoneFormLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.financialEstimationValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.table-custom-style {
  font-size: 12px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.table-cell-margin {
  margin: 2px 0px;
}
.custom-font {
  font-size: 12px;
}

.required-label label::after {
  content: " *";
  color: red;
}
</style>
